.custom-select-wrapper {
    position: relative;/* Posición relativa para poder manejar elementos internos */
}

.custom-select-selected {
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    margin: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    text-align: center;
}

.custom-select-image {
    width: 15%;/* Ajusta el tamaño de la imagen dentro del selector */
    margin-right: 5px;
}

.custom-select-text {
    font-size: 15px;
    font-weight: bold;
    white-space: nowrap;         /* Evita que el texto se divida en varias líneas */
    overflow: hidden;            /* Oculta el desbordamiento del contenido */
    text-overflow: ellipsis;     /* Muestra los tres puntos (...) cuando el texto es demasiado largo */
    align-items: center;
    width: 75%;                  /* Ocupa el 80% del ancho disponible */
}

.custom-select-text-pack {

    font-size: 15px;
    font-weight: bold;
    white-space: nowrap;         /* Evita que el texto se divida en varias líneas */
    overflow: hidden;            /* Oculta el desbordamiento del contenido */
    text-overflow: ellipsis;     /* Muestra los tres puntos (...) cuando el texto es demasiado largo */
    width: 75%; 
    /* align-items: center; */
}

.custom-select-subtext {
 /*    font-size: 12px;
    color: grey;
    margin-top: 5px;
    text-align: center; */
 /*   white-space: nowrap;         /* Evita que el texto se divida en varias líneas */
 /*   overflow: hidden;            /* Oculta el desbordamiento del contenido */
  /*  text-overflow: ellipsis;     /* Muestra los tres puntos (...) cuando el texto es demasiado largo */
}

.custom-select-arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
/*     display: inline-block; */
    padding: 3px;
    transform: rotate(45deg);
    margin-right: 10px;
    margin-left: 5px;
}

.custom-select-arrow.open {
    transform: rotate(-135deg);/* Cambia la dirección de la flecha al abrir el menú */
}




.custom-select-options {
    position: fixed;  /* Posicionamos el menú en relación con la ventana del navegador */
    top: 50px;        /* A 90px del borde superior de la pantalla */

    padding: 3px;
    margin: 0px 15px 0px 15px;
    background-color: white;
    border: 1px solid #ccc;
    max-width: 400px;
    max-height: 610px; /* Ajusta este valor según el tamaño total que quieras darle al contenedor */
    overflow-y: auto; /* Habilita el scroll para la página principal */
    z-index: 1001;
    border-radius: 4px;
    box-shadow: 0px 2px 200px rgba(0, 0, 0, 1);
}

.custom-select-option {
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.custom-select-option:hover {
    background-color: #ccc;/* Cambia el fondo cuando pasas el ratón por encima */
}

.custom-select-option-text {
    font-size: 15px;
    white-space: nowrap;         /* Evita que el texto se divida en varias líneas */
    overflow: hidden;            /* Oculta el desbordamiento del contenido */
    text-overflow: ellipsis;     /* Muestra los tres puntos (...) cuando el texto es demasiado largo */
    width: 80%; 
    align-items: center;
}

.custom-select-option-image {
    width: 12%;
    margin-right: 10px;
}




/* .contenedor-spinner{
    background-color: rgba(107, 159, 107, 0.566);
    padding: 15px;
} */