/* BLOQUEO GIRO HORIZONTAL*/



/* BLOQUEO ZOOM */

body {
  touch-action: manipulation; /* Desactiva la posibilidad de hacer zoom con el gesto de pellizcar en pantallas táctiles */
}

html {
  overflow-x: hidden; /* Previene el desplazamiento horizontal que podría causar el zoom */
  scroll-behavior: smooth;
}

* {
  touch-action: manipulation; /* Aplica la desactivación del zoom a todos los elementos */
}




/* CONTENEDORES PRINCIPALES*/

body {
/*   padding: 10px; */
  font-family: Arial, sans-serif;
  color: rgb(43, 54, 42);
  display: flex;
  justify-content: center;
  align-items: flex-start;
/*  min-height: 100vh; */
  background-image: url('../assets/fondo.jpg'); /* Ruta de la imagen */
  background-color: white;  
/*  background-image: url('../assets/fondo_dark.jpg'); /* Ruta de la imagen */
  background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el área de fondo */
 /*  background-repeat: no-repeat; /* Evita que la imagen se repita */
  /* background-position: top center; */ /* Centra la imagen horizontalmente, alinea con la parte superior */
  background-attachment: fixed; /* Fija la imagen para que no se mueva al hacer scroll */
  /* height: 100vh; */ /* Asegura que el body cubra toda la altura de la ventana */

  overflow-y: hidden; /* Habilita el scroll para la página principal */
  overflow-x: hidden; /* Deshabilita el scroll vertical en la página principal */
}



.home {
  display: flex;
  flex-direction: column;
  align-items: center;
/*   width: 100%; */
  /* max-width: 900px; */ /* Ancho máximo de 900px para el contenedor principal */
  max-width: 800px; /* Ancho máximo de 900px para el contenedor principal */
  /* margin: 0 auto; */ /* Centrar horizontalmente */
/*   box-sizing: border-box; */
/*  padding-top: 40px; /* Espacio para el Minibanner */
  padding-bottom: 100px; /* Espacio para el Header */
  padding-left: 15px; /* Espacio para el Minibanner */
  padding-right: 15px; /* Espacio para el Header */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra opcional para darle profundidad */
}

.home2 {
  display: flex;
  flex-direction: column;
  align-items: center;
/*   width: 100%; */
  /* max-width: 900px; */ /* Ancho máximo de 900px para el contenedor principal */
  max-width: 800px; /* Ancho máximo de 900px para el contenedor principal */
  /* margin: 0 auto; */ /* Centrar horizontalmente */
/*   box-sizing: border-box; */
  padding-top: 15px; /* Espacio para el Minibanner */
  padding-bottom: 100px; /* Espacio para el Header */
  padding-left: 15px; /* Espacio para el Minibanner */
  padding-right: 15px; /* Espacio para el Header */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra opcional para darle profundidad */
}

.home-act-ord {
  display: flex;
  flex-direction: column;
  align-items: center;
/*   width: 100%; */
  /* max-width: 900px; */ /* Ancho máximo de 900px para el contenedor principal */
  max-width: 800px; /* Ancho máximo de 900px para el contenedor principal */
  /* margin: 0 auto; */ /* Centrar horizontalmente */
/*   box-sizing: border-box; */
  padding-top: 5px; /* Espacio para el Minibanner */
  padding-bottom: 100px; /* Espacio para el Header */
  padding-left: 15px; /* Espacio para el Minibanner */
  padding-right: 15px; /* Espacio para el Header */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra opcional para darle profundidad */
  position: fixed; /* Hace que el contenedor esté fijo */
  top: 0; /* Lo fija en la parte superior de la pantalla */
  left: 0;
  right: 0;
  margin: auto; /* Centra el contenedor horizontalmente */
  z-index: 1000; /* Asegura que esté por encima de otros elementos si es necesario */
}

.home-vent {
  display: flex;
  flex-direction: column;
  align-items: center;
/*   width: 100%; */
  /* max-width: 900px; */ /* Ancho máximo de 900px para el contenedor principal */
  max-width: 800px; /* Ancho máximo de 900px para el contenedor principal */
  margin-top: 8px; /* Centrar horizontalmente */
/*   box-sizing: border-box; */
  background-color: rgba(249, 249, 249, 0.5); /* Fondo ligeramente transparente */
  padding: 15px; 
  border: 1px solid rgba(112, 164, 112, 0.905); /* Verde en los lados y bajo*/
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombra opcional para darle profundidad */
  border-radius: 7px;
}


/* MINI BANNER */

.minibanner {
  width: 100%; /* Ocupa todo el ancho de la pantalla */
  max-height: 80px; /* Ajusta la altura máxima de la imagen */
  position: fixed; /* Fija el minibanner en la parte superior */
  top: 0; /* Lo posiciona en la parte superior de la pantalla */
  left: 0; /* Lo posiciona alineado al borde izquierdo */
  padding-bottom: 0px;
  display: flex;
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  z-index: 998; /* Asegura que esté por encima de otros elementos */
  overflow: hidden; /* Oculta cualquier contenido que se salga del contenedor */
}

/* Estilo para el fondo desenfocado */
.minibanner-background {
  position: absolute;
  width: 100%; /* La imagen ocupa todo el ancho disponible */
  height: 80px; /* Ajusta la altura del fondo */
  z-index: -1; /* Envía la imagen de fondo detrás del contenido principal */
  top: -20px;
  /* left: 0; */
  filter: blur(10px); /* Aplica un desenfoque de 8px a la imagen de fondo */
  background-size: cover; /* La imagen de fondo cubre todo el área del contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

/* Estilo para la imagen principal */
.minibanner-principal img {
  width: 100%;
  max-height: 80px; /* Ajusta la altura máxima de la imagen */
  height: auto; /* Mantiene la proporción de la imagen */
  object-fit: contain; /* Ajusta la imagen dentro del contenedor sin deformarla */
  z-index: 1; /* Asegura que la imagen principal esté por encima del fondo */
}



/* RELLENO */

.mbrell {
  width: 100%; /* Ocupa todo el ancho de la pantalla */
  max-height: 80px; /* Ajusta la altura máxima de la imagen */
  padding-bottom: 0px;
  display: flex;
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  overflow: hidden; /* Oculta cualquier contenido que se salga del contenedor */
}

/* Estilo para la imagen principal */
.mbrell-principal img {
  width: 100%;
  max-height: 80px; /* Ajusta la altura máxima de la imagen */
  height: auto; /* Mantiene la proporción de la imagen */
  object-fit: contain; /* Ajusta la imagen dentro del contenedor sin deformarla */
  z-index: 1; /* Asegura que la imagen principal esté por encima del fondo */
}



/* BOTONES MENÚ */

/* Estilos para los elementos del home  IMAGEN Y BAJO RECUADRO TRANSPARENTE CON TEXTO */
/* .actos,
.ordenes,
.banner,
.filascomparsas,
.lugares,
.comercios,
.ajustes,
.acerca-de {
  width: 100%;
  margin-bottom: 30px;
  border-radius: 20px; 
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.3); 
  overflow: hidden; 
}

.actos img,
.ordenes img,
.banner img,
.filascomparsas img,
.lugares img,
.comercios img,
.ajustes img,
.acerca-de img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.element-text {
  width: 100%; 
  text-align: center; 
  font-weight: bold; 
  color: rgb(27, 83, 57); 
  font-size: 15px; 
  padding: 10px; 
  box-sizing: border-box; 
  letter-spacing: 0.3vw; 
} */

.image-header img {
  width: 100%; /* La imagen ocupará todo el ancho del contenedor */
  max-width: 800px; /* Limitará el ancho máximo a 800px */
  height: auto; /* Mantendrá la proporción para que no se deforme */
  display: block; /* Eliminará los espacios blancos adicionales alrededor de la imagen */
  margin: 0 auto; /* Centra la imagen si su tamaño es menor al contenedor */
}


/* Estilos para los elementos del home */
.actos,
.ordenes,
.banner,
.filascomparsas,
.lugares,
.comercios,
.ajustes,
.acerca-de {
  width: 100%;
  margin-bottom: 30px; /* Margen inferior */
  border-radius: 20px; /* Esquina superior izquierda */
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.4); /* Sombra en la parte inferior */
  overflow: hidden; /* Asegura que el contenido no sobresalga de los bordes redondeados */
  position: relative; /* Para posicionar el texto sobre la imagen */
}

/* Estilo para el contenedor de la imagen */
.actos img,
.ordenes img,
.banner img,
.filascomparsas img,
.lugares img,
.comercios img,
.ajustes img,
.acerca-de img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  position: relative; /* Para posicionar el destello */
}


/* Estilo del destello */
.actos::before,
.ordenes::before,
.banner::before,
.filascomparsas::before,
.lugares::before,
.comercios::before,
.ajustes::before,
.acerca-de::before {
  content: ''; /* Contenido vacío para el destello */
  position: absolute;
  top: 0;
  left: -150%; /* Comienza fuera de la imagen */
  width: 150%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,  /* Inicio totalmente transparente */
    rgba(255, 255, 255, 0.1) 40%, /* Transición suave */
    rgba(255, 255, 255, 0.6) 50%, /* Máxima intensidad */
    rgba(255, 255, 255, 0.1) 60%, /* Transición suave */
    rgba(255, 255, 255, 0) 100%  /* Final totalmente transparente */
  );
  transform: skewX(-20deg); /* Inclinación diagonal */
  animation: destello 10s infinite; /* Animación infinita */
  z-index: 2; /* Coloca el destello por encima de la imagen pero debajo del texto */
}

.actos::before {
  animation-delay: 0s; /* Sin retraso */
}

.filascomparsas::before {
  animation-delay: 2s; /* Retraso de 6 segundos */
}

.ordenes::before {
  animation-delay: 4s; /* Retraso de 2 segundos */
}

.banner::before {
  animation-delay: 6s; /* Retraso de 4 segundos */
}



.lugares::before {
  animation-delay: 5s; /* Retraso de 8 segundos */
}

.comercios::before {
  animation-delay: 10s; /* Retraso de 10 segundos */
}

.ajustes::before {
  animation-delay: 12s; /* Retraso de 12 segundos */
}

.acerca-de::before {
  animation-delay: 14s; /* Retraso de 14 segundos */
}


/* Animación del destello */
@keyframes destello {
  0% {
    left: -150%;
  }
  15% {
    left: 150%;
  }
  100% {
    left: 150%; /* Mantiene el destello fuera de la vista */
  }
}


.element-text {
  width: 100%; /* Asegura que el texto ocupe todo el ancho del contenedor */
  text-align: center; /* Alinea el texto al centro */
  font-weight: bold; /* Hace que el texto sea negrita */
  color: rgb(27, 83, 57); /* Color del texto verde oscuro menus */
  font-size: 15px; /* Tamaño del texto */
  padding: 10px; /* Espacio alrededor del texto */
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho total */
  letter-spacing: 0.3vw; /* Espaciado entre letras basado en el ancho de la ventana */
  
  /* Estilo para superponer el texto sobre la imagen */
  position: absolute;
  bottom: 0; /* Posiciona el texto en la parte inferior de la imagen */
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7); /* Fondo blanco con opacidad */
}

.element-text-fc {
  background-color: rgba(255, 255, 255, 0.3); /* Fondo blanco con opacidad */
}






/* BOTONES MENÚ ESPECIALES */

/* Ordenes and Banner */
.row {
  display: flex;
  width: 100%;
}

.ajustes,
.banner {
  width: 100%;
  margin-right: 5px; /* Margen inferior */
}

.ordenes,
.acerca-de {
  width: 100%;
  margin-left: 5px; /* Margen inferior */
}







/* VENTANAS */

.title-vent {
  color: rgb(12, 110, 12);
  margin: 15px 0 5px 0;
  font-size: 20px;
  text-align: center;
  width: 85%;
}

.text-vent {
  color: rgb(43, 54, 42);
  margin: 0px 0 0px 0;
  font-size: 13px;
  /* padding: 0px 50px 0px 50px; */
  width: 100%;
  white-space: normal;
  text-align: center;
}

.text-vent p {
  margin: 0; /* Elimina los márgenes */
  padding: 0; /* Elimina cualquier relleno */
  line-height: 1.2; /* Ajusta el espacio entre líneas si lo deseas */
}

.justify p {
  text-align: justify;
  margin-bottom: 20px;
}

.vent {
  margin-top: 30px;
  max-width: 560px;
  
}



/* BOTONES */
.boton {
  background-color: rgb(40, 167, 69); /* Color verde */
  border: 1px solid rgba(112, 164, 112, 0.905); 
  color: rgb(250, 250, 250);  /* Texto blanco */
  border-radius: 7px; /* Esquinas redondeadas */
  padding: 10px 0; /* Espaciado interno */
  text-align: center; /* Centrar el texto */
  width: 100%; /* Ocupa todo el ancho disponible */
  cursor: pointer; /* Cambia el cursor a mano para indicar clic */
  font-weight: bold; /* Hace el texto más grueso */
  transition: background-color 0.3s ease; /* Transición suave al cambiar el color */
  margin-top: 15px;
}

.boton:hover {
  background-color: #218838; /* Cambia de color al pasar el cursor */
}





/* NAV */
.nav {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  padding: 10px 0px 7px 0px;  /* Espaciado vertical */
  justify-content: center;  /* Coloca los elementos en los extremos */
  align-items: center;  /* Centra los elementos verticalmente */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7);  /* Sombra superior para darle un efecto elevado */
  background-color:rgba(112, 164, 112, 0.905);
}

.nav-content {
  max-width: 800px; /* Ancho máximo del contenido */
  max-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Alinea el contenido verticalmente al centro */
  padding: 7px 0px 7px 0px;  /* Espaciado vertical */
  width: 100%;
}

/* Estilo para cada elemento del header */
.nav-item {
  flex: 0 0 auto;  /* Evita que el elemento se expanda para ocupar más espacio */
  text-align: center;  /* Centra el contenido en cada elemento */
  cursor: pointer; /* Indica que todo el elemento es clicable */
  padding: 10px 25px 15px 25px;  /* Espaciado vertical */
}

.nav-item img {
  max-height: 30px; /* Ajusta la altura máxima de la imagen */
  object-fit: contain; /* Ajusta la imagen dentro del contenedor sin deformarla */
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1); /* Tamaño original */
  }
  50% {
    transform: scale(0.7); /* Encoge al 90% de su tamaño */
  }
}
.nav-item-aviso {
  flex: 0 0 auto;  /* Evita que el elemento se expanda para ocupar más espacio */
  text-align: center;  /* Centra el contenido en cada elemento */
  cursor: pointer; /* Indica que todo el elemento es clicable */
  animation: pulse 1s infinite; /* Aplica la animación */
}
.nav-item-aviso img {
  max-height: 30px; /* Ajusta la altura máxima de la imagen */
  object-fit: contain; /* Ajusta la imagen dentro del contenedor sin deformarla */
}

.profile-button {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Alinea el contenido hacia la derecha */
}
.nav-item.left {
  padding-top: 15px;
}

.nav-item button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.up-arrow {
  border: solid rgb(12, 110, 12);
  border-width: 0 2px 2px 0;
/*     display: inline-block; */
  padding: 3px;
  transform: rotate(225deg);
  margin-right: 5px;
  margin-left: 5px;
}
.up-arrow.open {
  transform: rotate(45deg);/* Cambia la dirección de la flecha al abrir el menú */
}



.right-arrow {
  border: solid rgb(12, 110, 12);
  border-width: 0 3px 3px 0;
/*     display: inline-block; */
  padding: 3px;
  transform: rotate(45deg);
  margin-right: 5px;
  margin-left: 5px;
}






/* VENTANA PERFIL Y MODAL COOKIES */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo semitransparente para el overlay */
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Alinea el contenido del modal en la parte inferior */
  z-index: 999; /* Asegura que la ventana esté por encima de otros elementos */
}

.modal-content {
  background-color: white;
  border-radius: 8px 8px 0 0; /* Bordes superiores redondeados */
  padding: 20px 20px 2px 20px;
  width: 100%;
  /* max-width: 800px; */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7);  /* Sombra superior para darle un efecto elevado */
  position: relative;
  animation: slideUp 0.5s ease-out; /* Animación de subida */
  margin-bottom: 50px; /* Espacio suficiente para que el contenido no quede oculto detrás del nav */
}

/* Animación para hacer que el modal aparezca de abajo a arriba */
@keyframes slideUp {
  from {
    transform: translateY(100%); /* Empieza fuera de la pantalla */
  }
  to {
    transform: translateY(0); /* Llega a su posición final */
  }
}

/* Añadir la animación de bajada al cerrar */
@keyframes slideDown {
  from {
    transform: translateY(0); /* Empieza en su posición final */
  }
  to {
    transform: translateY(100%); /* Se desliza hacia abajo */
  }
}

/* Clase para aplicar cuando el modal se está cerrando */
.modal-content.closing {
  animation: slideDown 0.5s ease-out;
}

.modal-content-int {
  margin: 0 auto; /* Centra horizontalmente */
  max-width: 800px;
  width: 100%;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
  margin: 8px;
}

.perfil-content {
  padding: 20px 20px 2px 20px;
  width: 100%;
  margin-bottom: 20px; /* Espacio suficiente para que el contenido no quede oculto detrás del nav */
}

.perfil-section {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra los items horizontalmente */
  gap: 0px; /* Añade un margen entre cada item */
  margin: 15px 0px 0px 0px;
}

.perfil-item {
  display: flex;
  flex-direction: column; /* Los elementos dentro de perfil-item se apilan en columna */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  text-align: center; /* Centra el texto */
  font-size: 1rem;
  color: rgb(27, 83, 57);
  padding: 15px; /* Espaciado arriba y abajo del título */
  border-bottom: 1px solid rgba(112, 164, 112, 0.905);
  cursor: pointer;
  text-decoration: none;
}

/* Contenedor que envuelve la imagen */
.perfil-image-container {
  width: auto; /* Tamaño total del contenedor */
  height: auto;
  max-width: 35%;
  margin: 10px 0 5px 0;
  padding: 15px; /* Espacio entre la imagen y el borde */
  border-radius: 50%; /* Hace el contenedor redondo */
  border: 1px solid rgb(67, 128, 99); /* Borde azul */
  display: flex;
  background-color: rgb(239, 255, 239);
  justify-content: center;
  align-items: center;
}

.perfil-image {
  width: 80px; /* Aumenta el tamaño de la imagen */
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.nombre-fila {
  color: rgb(12, 110, 12);
  margin: 0px 0 5px 0;
  font-size: 1.5rem;
  text-align: center;

}

.perfil-actos h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.perfil-actos ul {
  list-style-type: none;
  padding: 0;
}

.perfil-actos li {
  margin-bottom: 10px;
}






/* COOKIES*/

.text-cook {
  color: rgb(43, 54, 42);
  margin: 0px 0 0px 0;
  font-size: 13px;
  /* padding: 0px 50px 0px 50px; */
  width: 100%;
}

.text-resal {
  font-size: 14px;
}


.boton-pref-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Para centrar los botones horizontalmente */
  gap: 10px; /* Espacio entre los botones */
}

.boton-pref {
  flex: 1 1 200px; /* Los botones ocuparán al menos 200px, pero crecerán si hay espacio */
  max-width: 300px; /* Máximo ancho para cada botón */
  background-color: white; /* Color verde */
  border: 1px solid rgba(112, 164, 112, 0.905); 
  color: rgb(43, 54, 42);
  border-radius: 4px; /* Esquinas redondeadas */
  padding: 3px 0; /* Espaciado interno */
}

.boton-pref-ver {
  flex: 1 1 200px;
  max-width: 300px;
  background-color: rgb(40, 167, 69); /* Color verde */
  border: 1px solid rgba(112, 164, 112, 0.905); 
  color: rgb(250, 250, 250);  /* Texto blanco */
  border-radius: 4px; /* Esquinas redondeadas */
  padding: 3px 0; /* Espaciado interno */
  transition: background-color 0.3s ease; /* Transición suave al cambiar el color */
}

/* Para asegurar que cuando solo haya un botón abajo, ocupe el mismo ancho que los de arriba */
.boton-pref-container > * {
  box-sizing: border-box;
}

.boton-pref-ver:hover {
  background-color: #218838; /* Cambia de color al pasar el cursor */
}