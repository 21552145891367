/* grid COMPARSAS */
.grid-container {
  display: grid;
  /* width: 500px; */
  /* max-width: 800px;  */
  width: 100%;
  /* max-width: 100%; */
  grid-template-columns: repeat(2, 1fr); /* 2 columnas de igual tamaño */
  gap: 16px;
  padding-left: 15px;
  padding-right: 15px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  max-width: 100%;
/*   min-width: 120px; */
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(249, 249, 249, 0.5); /* Fondo ligeramente transparente */
  border: 1px solid #ddd;
  border-radius: 7px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /*box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.3);
  text-decoration: none; /* Elimina el subrayado */
  color: inherit; /* Hereda el color del contenedor, no el del enlace */
}


.grid-item-large {
  grid-column: span 2; /* Ocupa dos columnas */
  text-decoration: none; /* Elimina el subrayado */
  color: inherit; /* Hereda el color del contenedor, no el del enlace */
}

.grid-image {
/*  width: 150px; /* Mantén un tamaño fijo para todas las imágenes */
/*  height: 100px; /* Mantén un tamaño fijo para todas las imágenes */
  max-width: 100%;
  max-height: auto;
  object-fit: contain; /* Ajusta la imagen al contenedor sin recortarla ni deformarla */
  margin-bottom: 10px;
}

.grid-item p {
  margin: 0;
  font-size: 13px;
  text-align: center;
}

.grid-title {
  grid-column: span 2; /* Hace que el título ocupe dos columnas */
  text-align: left;
  font-size: 20px;
  color: rgb(27, 83, 57);
  margin-top: 5px; /* Espaciado arriba y abajo del título */
}



.grid-item-large-small {
  grid-column: span 2; /* Ocupa dos columnas */
  display: flex;
  align-items: center;
  padding: 5px;
  padding-left: 10px;
  height: 30px; /* Ajusta esta altura según sea necesario */
  background-color: rgba(249, 249, 249, 0.5); /* Fondo ligeramente transparente */
  border: 1px solid #ddd;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: inherit;
  /* margin-left: 165px; */
  margin-right: 0px;
}

.grid-item-large-small img {
  height: 100%; /* La imagen toma toda la altura del contenedor */
  width: auto;  /* El ancho se ajusta automáticamente para mantener la proporción */
  margin: 0;
}

.grid-item-large-small .text-container {
  flex: 1; /* Ocupa todo el espacio restante */
  display: flex;
  align-items: center; /* Centra verticalmente el contenido */
  justify-content: center; /* Centra horizontalmente el contenido */
  margin-left: 10px;
  margin-right: 10px;
}

.grid-item-large-small b {
  font-size: 16px;
  margin: 0;
  text-align: center;
}




/* TIPOS COMERCIO */

.grid-container-t {
  display: flex;
  flex-direction: column;
  width: 100%;
 /*  position: relative; */
}

.grid-item-t {
  display: flex;
  align-items: center; /* Alinea verticalmente la imagen y el texto */
  width: 100%;
  margin-top: 8px; /* Espacio entre los elementos, ajusta según lo necesites */
  background-color: rgba(249, 249, 249, 0.5); /* Fondo ligeramente transparente */
  border: 1px solid rgb(237, 237, 237); /* Borde botones tipo */
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none; /* Elimina el subrayado */
  color: inherit; /* Hereda el color del contenedor, no el del enlace */
  overflow: hidden; /* Asegura que el contenido no sobresalga de los bordes redondeados */
}

.grid-image-t {
  width: 50%; /* O el porcentaje que consideres adecuado */
  height: auto;
  display: block;
  object-fit: cover;
}

.grid-text-t {
  margin-left: 20px; /* Espacio entre la imagen y el texto */
  font-size: 15px; /* Ajusta el tamaño de la fuente según lo necesites */
  background-color: transparent; /* Asegura que no haya fondo */
}



/* TIPOS ÓRDENES */

.grid-image-b {
  width: 15%; /* O el porcentaje que consideres adecuado */
  height: auto;
  display: block;
  object-fit: cover;
  margin: 10px;
}

.grid-text-b {
  width: 100%;
  font-size: 15px; /* Ajusta el tamaño de la fuente según lo necesites */
  background-color: transparent; /* Asegura que no haya fondo */
  text-align: center;
}




/* ACTOS */
.aviso-contenedor {
  display: flex;
  align-items: center; /* Alinea los elementos verticalmente */
}

.aviso-texto {
  flex-grow: 1; /* Permite que el texto ocupe el espacio restante */
  display: flex; /* Configura como flexbox para centrar el contenido */
  flex-direction: column; /* Asegura que el contenido se mantenga en una sola columna */
  justify-content: center; /* Centra el contenido verticalmente */
  align-items: center; /* Centra el contenido horizontalmente */
  text-align: center; /* Asegura que el texto esté centrado */
}

.icono-leido {
  position: absolute;
  top: 10px;
  right: 10px; /* Esquina superior derecha */
}

.icono-leido img {
  width: 30px; /* Ajusta el tamaño según sea necesario */
  height: 30px;
}

.aviso-actos {
  position: relative; /* Necesario para que el ícono se posicione relativo al contenedor */
  background-color: rgba(255, 0, 0); /* Fondo rojo transparente */
  color: white ; /* Letras blancas */
  font-size: 13px; /* Tamaño de letra */
  width: 100%; /* Ocupa todo el ancho disponible */
  padding: 0px 20px 0px 20px;
  box-sizing: border-box; /* Incluye el padding en el ancho total */
  border-radius: 7px;
  border: 1px solid rgba(255, 0, 0); /* Letras blancas */
  text-align: center; /* Alineación centrada del texto */
  margin-top: 10px;
}

.aviso-actos b {
  font-size: 15px; /* Tamaño de letra */
}

.aviso-actos-int {
  position: relative; /* Necesario para que el ícono se posicione relativo al contenedor */
  background-color: rgba(255, 255, 255); /* Fondo rojo transparente */
  color: rgba(255, 0, 0);
  font-size: 15px; /* Tamaño de letra */
  width: 100%; /* Ocupa todo el ancho disponible */
  padding: 10px 20px 10px 20px;
  box-sizing: border-box; /* Incluye el padding en el ancho total */
  border-radius: 7px;
  border: 1px solid rgba(255, 0, 0); /* Letras blancas */
  text-align: center; /* Alineación centrada del texto */
  margin-top: 10px;
}

.aviso-actos-int b {
  font-size: 17px; /* Tamaño de letra */
}

.aviso-icon {
  width: 40px;
  height: 40px;
}

.grid-container-a {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.grid-separator-a {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  background-color: rgba(107, 159, 107, 0.566); /* Verde transparente */
  border: 1px solid rgba(112, 164, 112, 0.905);
  border-radius: 8px 8px 0 0; /* Esquinas superiores redondeadas */
  text-align: center;
}

.grid-separator-bottom-a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
  background-color: rgba(107, 159, 107, 0.566); /* Verde transparente */
  border: 1px solid rgba(112, 164, 112, 0.905);
  border-top: none;
  border-radius: 0 0 8px 8px; /* Esquinas inferiores redondeadas */
  text-align: center;
}

.grid-text-a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; /* Ajusta según lo necesites */
  padding: 5px;
  color: rgb(250, 250, 250); 
  letter-spacing: 0.5vw; /* Espaciado entre letras basado en el ancho de la ventana */
}


.grid-item-a {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(249, 249, 249, 0.5); /* Fondo ligeramente transparente */
  border: 1px solid rgba(112, 164, 112, 0.905); /* Verde en los lados y bajo*/
  border-top: none; /* Sin borde superior */
  text-decoration: none; /* Elimina el subrayado */
  color: inherit;
  letter-spacing: 0.2vw; /* Espaciado entre letras basado en el ancho de la ventana */
}

.grid-content-a {
  display: flex;
}

.grid-content-a:hover {
  background-color: rgba(144, 255, 134, 0.401);
}
.grid-content-a:active {
  background-color: rgba(144, 255, 134, 0.401);
}

.grid-column-image-a {
  width: 20%; /* Ajusta según lo necesites */
}

.grid-image-a {
  width: 70%;
  height: auto;
  display: block;
  object-fit: cover;
  margin: 8px 10px 8px 10px; /* Ajusta el margen según sea necesario */
}

.grid-column-text-a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%; /* Ajusta según lo necesites */
}

.grid-row-top-a {
  display: flex;
  justify-content: space-between;
}

.grid-item-title-a {
  flex: 1;
  font-weight: bold;
  margin-right: 16px;
  margin-top: 8px;
}

.grid-item-start-a {
  text-align: right;
  margin-right: 10px;
  margin-top: 8px;
  margin-bottom: 0px;
  font-size: 12px;
}

.grid-row-bottom-a {
  display: flex;
  justify-content: flex-start;
}

.grid-item-dif-a {
  align-self: flex-start;
  margin-bottom: 2px;
  font-size: 13px;
}

.grid-bottom-border-a {
  width: 100%;
  height: 5px;
  margin: 0px 5px 8px 0px;
  border-radius: 2px 2px 2px 2px;
  /*background-color: rgba(0, 255, 0, 0.3); /* Verde de relleno */
  /*background-color: rgba(255, 0, 0, 0.3); /* Verde de relleno */
  background-color: rgba(210, 134, 22, 0.3); /* Verde de relleno */
}

.grid-row-bottom-a {
  display: flex;
  justify-content: flex-start;
}

.grid-item-dif-a {
  align-self: flex-start;
  margin-bottom: 8px;
  margin-top: 8px;
}


.grid-row-dif-a {
  display: flex;
  position: relative; /* Para permitir la superposición */
  height: 5px;
  width: 100%;
  margin: 16px 0px 0px 0px; /* Mantener los mismos márgenes */
  overflow: hidden; /* Esto asegura que los elementos hijos no se salgan del contenedor */
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.grid-bottom-time-b {
  width: 100%; /* Esta barra ocupa todo el contenedor */
  height: 5px;
  margin-right: 12px; /* Restaurar los márgenes anteriores */
  border-radius: 2px;
  background-color: rgba(66, 66, 66, 0.3); /* Barra de duración total */
  position: relative; /* Barra estática, no cambia de tamaño */
  z-index: 1; /* Debajo de la barra de tiempo transcurrido */
}

.grid-bottom-time-a {
  position: absolute; /* Barra de tiempo transcurrido */
  top: 0;
  left: 0;
  height: 5px;
  margin-right: 10px; /* Mantener los mismos márgenes */
  border-radius: 2px;
  background: linear-gradient(
    90deg,
    rgba(0, 195, 0, 0.4) 25%,
    rgba(0, 255, 0, 0.7) 50%,
    rgba(0, 195, 0, 0.4) 75%
  ); /* Gradiente con un tono más brillante en el centro */
  background-size: 200px 100%; /* Tamaño del gradiente */
  animation: shimmer 2s infinite; /* Aplicar animación */
  z-index: 2; /* Sobre la barra de duración */
}







/* ACTOS OBLIGADOS */

.grid-column-image-obli-a {
  width: 17%; /* Ajusta según lo necesites */
}

.grid-image-obli-a {
  width: 60%;
  height: auto;
  display: block;
  object-fit: cover;
  margin: 8px 10px 8px 10px; /* Ajusta el margen según sea necesario */
}

.grid-item-start-a-obli {
  text-align: right;
  margin-left: 10px;
  margin-top: 8px;
  font-size: 12px;
}

.grid-item-title-a-obli {
  flex: 1;
  font-weight: bold;
  margin-right: 12px;
  margin-left: 16px;
  margin-top: 8px;
  font-size: 12px;
}

.grid-column-text-a-obli {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inner-scroll-container-obli {
  width: 100%;
  max-height: 11vh; /* Altura fija para el contenedor interno */
  overflow-y: auto; /* Habilita el scroll interno */
  overflow-x: hidden;
  padding: 15px;
  margin-top: 16px;
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.5); /* Sombra interior */
}

.grup-star {
  display: flex; /* Flexbox para alinear horizontalmente */
  align-items: center; /* Centra verticalmente la imagen y el texto */
}

.star {
  width: 1em; /* Ajusta el tamaño de la estrella a la altura de la fuente */
  height: 1em; /* Hace que la altura sea igual a la del texto */
  margin: 0px 8px; /* Añade espacio entre la estrella y el texto */
}








.home-actos {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px; /* Ancho máximo de 900px para el contenedor principal */
  padding-top: 120px; /* Espacio para el Minibanner */
 /* padding-bottom: 10px; /* Espacio para el Header */

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra opcional para darle profundidad */
}


.outer-container {
  height: 100vh; /* Ocupa toda la altura de la ventana */
  /*overflow-y: auto; /* Habilita el scroll para la página principal */
 /* overflow-x: hidden; /* Deshabilita el scroll vertical en la página principal */
  padding: 10px, 10px, 10px, 10px;
}

.inner-scroll-container {
  max-height: 60vh; /* Altura fija para el contenedor interno */
  overflow-y: auto; /* Habilita el scroll interno */
  overflow-x: hidden;
  padding: 15px;
  margin-top: 16px;
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.5); /* Sombra interior */
}

