/* Contenedor principal */
.home-ficha {
  display: flex; /* Cambiado de 'column' a 'flex' para utilizar flexbox */
  flex-direction: column; /* Asegura que los elementos se apilen verticalmente */
  align-items: center;
  max-width: 800px; /* Ancho máximo de 800px para el contenedor principal */
  margin: 0 auto; /* Centrado horizontal */
  padding-bottom: 100px; /* Espacio para el Header */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra opcional para darle profundidad */
  position: relative;
  color: rgb(43, 54, 42); /* Color de texto */
}


/* PRIMER DIV: Nombre del padre */
.nombre-padre {
  width: 100%;
  text-align: right;
  padding: 5px 0px;
  font-size: 15px;
  color: white;
  margin-top: 25px;
  background: linear-gradient(to left, rgb(107, 159, 107), transparent); /* Degradado hacia izq */
  background: linear-gradient(to right, transparent 0%, rgba(135, 190, 16, 0.878) 80%, rgb(107, 159, 107) 100%, transparent 100%);
}
.nombre-padre p {
  margin: 0px 25px;
}

/* IMAGEN PRINCIPAL: Ajustada al ancho de la pantalla */
/* JPG */
.foto-principal {
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
  width: 100%; /* El contenedor toma el ancho completo */
}

/* Estilos generales para todas las imágenes */
.foto-principal img {
  max-width: 100%; /* La imagen no puede exceder el ancho del contenedor */
  height: auto; /* Mantiene la proporción de la altura */
  display: block; /* Elimina el espacio inferior que las imágenes pueden crear */
}

/* Específico para imágenes PNG */
.imagen-png {
  width: auto; /* Mantén el ancho natural */
  max-width: 150px; /* No se agranda más allá del ancho del contenedor */
  max-height: 150px; /* Limita la altura máxima a la altura de la ventana */
  margin: 20px;
}

/* IMAGEN FLOTANTE */
.ico-foto-flotante {
  position: absolute;
  top: 5px;
  left: 0px;
}

.ico-foto-flotante img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

/* SEGUNDO DIV: Nombre principal centrado y en negrita */
.nombre-principal {
  width: 100%; /* Ocupa el ancho completo del contenedor */
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  background: linear-gradient(to right, transparent 0%, rgba(135, 190, 16, 0.878) 15%, rgb(107, 159, 107) 85%, transparent 100%);
  padding: 0.5px 15px; /* Margen de 15px en los lados para el texto */
  color: white;
  box-sizing: border-box; /* Asegura que el padding no exceda el 100% del ancho */
  word-wrap: break-word; /* Permite que el texto pase a la siguiente línea si es necesario */
}
.nombre-principal p{
  margin: 10px 30px;
}

/* Contenido general */
.contenido {
  padding: 15px;
}

/* AÑO CENTRADO */
.anyo {
  text-align: center;
  font-size: 18px;
  margin-top: 25px;
}

/* INICIO (Fecha y hora) */
.inicio {
  text-align: center;
  color: green;
  font-size: 20px;
  margin: 15px 0px 0px 0px;
}

.duracion {
  font-size: 14px;
  color: gray;
  text-align: center;
}
.duracion p{
  margin: 5px 0px 0px 0px;
}

/* IMPORTANTE: centrado, verde y en negrita */
.importante {
  text-align: center;
  color: green;
  font-weight: bold;
  font-size: 15px;
  margin-top: 50px;
}

/* DESCRIPCIÓN: Texto justificado */
.descripcion {
  text-align: justify;
  font-size: 14px;
  margin-top: 50px;
}

/* DIRECCIÓN Y CONTACTO: Ambos en un div separado */
.direccion-contacto p {
  margin: 5px 0;
  font-size: 14px;
}



/* REDES SOCIALES */
.container-redes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px 0;
  gap: 10px; /* Añade espacio entre los elementos */
}

.social {
  width: 55px;
  border-radius: 10px;
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
}

.social img {
  width: 25px; /* Ajusta el tamaño de la imagen según tus necesidades */
  height: auto;
}

.social a {
  display: flex;
  margin: 0;
  padding: 0;
}

.social:hover {
  transform: scale(1.05);
}




/* MODIFICAR DATOS */
.correo {
  text-align: center;
  font-size: 13px;
  margin: 120px 0px 0px 0px;
}


.capitania {
  font-size: 16px;
  color: gray;
  text-align: center;
  margin: 30px;
}
.capitania p{
  margin: 15px 0px 0px 0px;
}

/* IMAGEN CAPITANIAS: Ajustada al ancho de la pantalla */
.foto-capitania {
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center;      /* Centrar verticalmente (opcional) */
}

.foto-capitania img {
  width: auto; 
  max-width: 70%; /* Asegura que la imagen no exceda el ancho de la pantalla */
  height: auto;    /* Mantiene la proporción */
  object-fit: cover;
  display: block;
}